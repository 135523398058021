import {
   SET_SCHOOLS,
   ADD_SCHOOL,
   UPDATE_SCHOOL,
   DELETE_SCHOOL,
} from '../actions';

export default ( state = {
   schools: [],
}, action) => {

   const {
      schools,
      school,
   } = action;

   switch (action.type) {
      
      case SET_SCHOOLS: {
         return {
            ...state,
            schools: schools
         }
      }

      case ADD_SCHOOL: {
         return {
            ...state,
            schools: [school, ...state.schools]
         }

      }
      
      case UPDATE_SCHOOL: {
         let updatedSchool = state.schools.find(x => x._id === school._id);
         let updatedSchoolIndex = state.schools.indexOf(updatedSchool);
         let schools = state.schools;
         schools[updatedSchoolIndex] = school;

         return {
            ...state, 
            schools: [...schools]
         }
      }
      
      case DELETE_SCHOOL: {

         let deletedSchool = state.schools.find(x => x._id === school);
         return {
            ...state,
            schools: [...state.schools.filter(x => x !== deletedSchool)]
         }
      }

      default:
            return state;
   }
}