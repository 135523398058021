import React from 'react';
import { NavLink } from "react-router-dom";

const school = () => {
  return (
    <section className='content'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <NavLink to={`/schools`} >
            <img className='w-5 p-1 cursor-pointer' src="../images/ico/back.svg" alt="back" />
          </NavLink>
          <div className='cursor-pointer flex ml-4 items-center justify-center w-16 h-16 rounded-full overflow-hidden'>
            <img src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" className='object-cover' />
          </div>
          <div className='text-xl font-medium ml-4'>Відродження, приватна середня загальноосвітня школа </div>
        </div>
        <div className='gap-3 flex items-center'>
          <div className='rounded-full w-10 h-10  flex items-center justify-center border border-transparent hover:border-lightGray cursor-pointer'>
            <img className='w-6' src="../images/ico/edit.svg" alt="edit" />
          </div>
          <div className=' rounded-full w-10 h-10 flex items-center justify-center border border-transparent hover:border-lightGray cursor-pointer'>
            <img className='w-6' src="../images/ico/delete.svg" alt="edit" />
          </div>
        </div>
      </div>

      <div className='p-10 mt-5 border border-lightGray rounded-3xl'>
        <div className='flex border-b border-lightGray pb-5'>
          <div className='flex-1'>
            <p className='text-lg font-medium'>Про нас</p>
            <p className='mt-1'>Ми несемо моральну та правову відповідальність за свої дії, а кожен наш вчинок несе за собою певний результат. Тож ми навчаємо наших ліцеїстів бути відповідальними та свідомими. Адже наше майбутнє залежить від того, що ми робимо, думаємо та творимо сьогодні.</p>
          </div>

          <div className='flex-1 ml-20'>
            <div className='flex items-center mt-3 gap-3'>
              <img src="../images/ico/location.svg" className='w-5' alt="school location" />
              <div>Львів, вул. Липинського, 1</div>
            </div>
            <div className='flex items-center mt-3 gap-3'>
              <img src="../images/ico/phone.svg" className='w-5' alt="school phone number" />
              <div>252-03-01</div>
            </div>
            <div className='flex items-center mt-3 gap-3'>
              <img src="../images/ico/students.svg" className='w-5' alt="number of students" />
              <div>К-сть учнів 129</div>
            </div>
          </div>
        </div>

        <div className='flex mt-5 gap-10'>

          <div className='flex-1'>
            <p className='text-lg font-medium'>Вчителі</p>
            <div className='p-3 rounded-3xl border border-lightGray flex justify-between mt-2'>
              <div className='flex items-center gap-4'>
                <div className='cursor-pointer flex items-center justify-center w-16 h-16 rounded-full overflow-hidden'>
                  <img src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" className='object-cover' />
                </div>
                <div>
                  <div>Шаповалова Л. О.</div>
                  <div className='text-sm text-gray'>+380987654321</div>
                </div>
              </div>
              <div className='gap-3 flex items-center'>
                <div className='rounded-full w-10 h-10  flex items-center justify-center border border-transparent transition hover:border-lightGray cursor-pointer'>
                  <img className='w-6' src="../images/ico/edit.svg" alt="edit" />
                </div>
                <div className=' rounded-full w-10 h-10 flex items-center justify-center border border-transparent transition hover:border-lightGray cursor-pointer'>
                  <img className='w-6' src="../images/ico/delete.svg" alt="edit" />
                </div>
              </div>
            </div>
          </div>

          <div className='flex-1'>
            <p className='text-lg font-medium'>Класи</p>
            <div className='px-6 py-3 rounded-3xl border border-lightGray flex justify-between mt-2'>
              <div className='flex items-center'>
                <p className='font-medium'>7-A</p>
                <div className='flex items-center gap-3 ml-10'>
                  <img src="../images/ico/students.svg" className='w-5' alt="number of students" />
                  <div>К-сть учнів 129</div>
                </div>
              </div>
              <div className='gap-3 flex items-center'>
                <div className='rounded-full w-10 h-10  flex items-center justify-center border border-transparent transition hover:border-lightGray cursor-pointer'>
                  <img className='w-6' src="../images/ico/edit.svg" alt="edit" />
                </div>
                <div className=' rounded-full w-10 h-10 flex items-center justify-center border border-transparent transition hover:border-lightGray cursor-pointer'>
                  <img className='w-6' src="../images/ico/delete.svg" alt="edit" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default school