import React, { useEffect, Suspense, useState } from "react";
import { connect } from "react-redux";
import './App.scss';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Login from './pages/login/login.js';
import Menu from './components/menu/menu';
import ProtectedRoute from './ProtectedRoute';
import Schools from './pages/schools/schools';
import School from './pages/school/school';
import { currentUser } from "./actions/currentUser";
import Popup from './components/popup/popup';

const mapStateToProps = (state) => ({
  ...state.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  currentUser: (payload) => {
    return dispatch(currentUser(payload));
  },
});

function App(props) {
  const { user } = props;
  // const [authChecked, setAuthChecked] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    if (token != null) {
      setIsAuthenticated(true)
      currentUser({ user: true });
      navigate('/');
    } else {
      setIsAuthenticated(false);
      currentUser({ user: false });
      navigate('/login');
    }
  }, [token]);

  useEffect(() => {
    console.log('user', user)
  }, [user])

  return (
    <div className="App">
      <div className="contentWrapper">
        <Popup />
        {isAuthenticated && <Menu />}
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute user={isAuthenticated}>
                <Schools />
              </ProtectedRoute>
            }
          />
        
          <Route path="/login" element={<Login />} />
          <Route
            path="/schools"
            element={
              <ProtectedRoute user={isAuthenticated}>
                <Schools />
              </ProtectedRoute>
            }
          />
          <Route
            path="/school/:schoolId"
            element={
              <ProtectedRoute user={isAuthenticated}>
                <School />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
