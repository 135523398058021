import { combineReducers } from 'redux';
import popups              from './popup';
import currentUser         from './currentUser';
import search              from './search';
import notifications       from './notification';
import schools             from './schools'

const rootReducer = combineReducers({
    popups,
    currentUser,
    search,
    notifications,
    schools
});


export default (state, action) =>
  rootReducer(action.type === 'USER_LOGOUT' ? undefined : state, action);