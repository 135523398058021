import React            from 'react';
import { connect }      from 'react-redux';
import Dialog           from '@mui/material/Dialog';
import DialogContent    from '@mui/material/DialogContent';

import SchoolPopup      from '../popups/schoolPopup/schoolPopup';
import Confirmation     from '../popups/confirmation/confirmation';

import {
   closePopup,
} from '../../actions/popup';

const mapStateToProps = state => ({
   ...state.popups
});

const mapDispatchToProps = dispatch => ({
   closePopup: () => {
      return dispatch(closePopup());
   },
})

const Popup = (props) => {

   const theme = localStorage.getItem("theme");

   
   const handleClose = () => {
      props.closePopup();
   }
   
   const map = {
      "SchoolPopup": SchoolPopup,
      'Confirmation': Confirmation
   }


   const CurrentPopup = map[props.currentPopup];
   if (props.currentPopup === 'AddWord') {
      return (
         <div>
            <Dialog
               open={props.showPopup}
               onClose={handleClose}
               maxWidth="lg"
               className="dialog"
               transitionDuration={100}
            >
               <DialogContent className="dialog">
                  <CurrentPopup info={props.info} />
               </DialogContent>

            </Dialog>
         </div>
      )
   } else {
      return (
         <Dialog
            open={props.showPopup}
            onClose={handleClose}
            maxWidth="lg"
            className="dialog"
            transitionDuration={100}
         >
            <DialogContent className="cursor-pointer dialog--blurred dialog customBorder bg-whiteTransparent dark:bg-darkModeDark">
               <div className='flex justify-between'>
                  {props.info.title &&
                  <div className='font-bold text-2xl'>{props.info.title}</div>
                  }
               <div className='rounded border border-lightGray transition hover:border-gray w-7 h-7 ml-auto flex items-center justify-center'>
                  <img src='./images/ico/close.svg' alt="" onClick={handleClose} className='w-min block' />
               </div>
               </div>
             
               <CurrentPopup info={props.info} />
            </DialogContent>

         </Dialog>
      )
   }
}

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Popup);