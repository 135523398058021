import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';


const EditDeleteButtons = () => {
    return (
        <>
            <ButtonBase className='rounded-3xl' onClick={(event) => event.preventDefault()}>
                <div className='flex items-center gap-3 cursor-pointer border border-transparent transition rounded-3xl hover:border-lightGray py-2 pl-3 pr-4'>
                    <img className='w-5' src="./images/ico/edit.svg" alt="edit" />
                    Редагувати
                </div>
            </ButtonBase>
            <ButtonBase className='rounded-3xl' onClick={(event) => event.preventDefault()} >
                <div className='flex items-center gap-3 cursor-pointer border border-transparent transition rounded-3xl hover:border-lightGray py-2 pl-3 pr-4'>
                    <img className='w-5' src="./images/ico/delete.svg" alt="edit" />
                    Видалити
                </div>
            </ButtonBase>
        </>
    )
}

export default EditDeleteButtons