import React, { useEffect, useState } from 'react';
import ButtonBase from '@mui/material/ButtonBase';

const SchoolPopup = () => {

    const [schoolData, setSchoolData] = useState({
        name: '',
        phone: '',
        olb: '',
        city: '',
        street: '',
        description: ''
    })

    return (
        <div className='mt-7'>
            <div className='flex'>
                <div className='flex-1'>
                    <div className='relative'>
                        <img className='absolute top-3 left-2.5 w-5' src="./images/ico/schoolName.svg" alt="school name" />
                        <input autoFocus required className={false ? 'border border-red rounded-lg w-full pl-10 pr-3 py-1 placeholder:text-gray' : 'border border-lightGray rounded-lg w-full pl-10 pr-3 py-2 placeholder:text-gray'} placeholder='Назва школи' type="text" value={schoolData.name} onChange={(e) => setSchoolData({ ...schoolData, name: e.target.value })} />
                    </div>
                    <div className='relative mt-4'>
                        <img className='absolute top-2.5 left-3 w-4' src="./images/ico/phone.svg" alt="phone" />
                        <input required className={false ? 'border border-red rounded-lg w-full pl-10 pr-3 py-1 placeholder:text-gray' : 'border border-lightGray rounded-lg w-full pl-10 pr-3 py-2 placeholder:text-gray'} placeholder='Номер телефону' type="text" value={schoolData.phone} onChange={(e) => setSchoolData({ ...schoolData, phone: e.target.value })} />
                    </div>
                    <div className='relative mt-4'>
                        <img className='absolute top-3 left-3 w-4' src="./images/ico/location.svg" alt="location" />
                        <input autoFocus required className={false ? 'border border-red rounded-lg w-full pl-10 pr-3 py-1 placeholder:text-gray' : 'border border-lightGray rounded-lg w-full pl-10 pr-3 py-2 placeholder:text-gray'} placeholder='Область' type="text" value={schoolData.obl} onChange={(e) => setSchoolData({ ...schoolData, obl: e.target.value })} />
                    </div>
                </div>
                <div className='border border-lime rounded-lg border-dashed w-56 ml-5 relative flex items-center justify-center'>
                    <input type="file" className='w-full h-full absolute opacity-0 cursor-pointer' />
                    <div className='flex flex-col items-center w-10/12 text-center'>
                        <p className='text-sm'>Перетягніть фото сюди або</p>
            <ButtonBase className='rounded-full mx-auto block mt-1'>
                        <div className='bg-blue rounded-full py-1 px-3 text-white text-sm'>Завантажити</div>
                        </ButtonBase>
                    </div>
                </div>

            </div>
            <div className='relative mt-4'>
                <img className='absolute top-2.5 left-3 w-4' src="./images/ico/location.svg" alt="location" />
                <input required className={false ? 'border border-red rounded-lg w-full pl-10 pr-3 py-1 placeholder:text-gray' : 'border border-lightGray rounded-lg w-full pl-10 pr-3 py-2 placeholder:text-gray'} placeholder='Місто' type="text" value={schoolData.city} onChange={(e) => setSchoolData({ ...schoolData, city: e.target.value })} />
            </div>
            <div className='relative mt-4'>
                <img className='absolute top-2.5 left-3 w-4' src="./images/ico/location.svg" alt="location" />
                <input required className={false ? 'border border-red rounded-lg w-full pl-10 pr-3 py-1 placeholder:text-gray' : 'border border-lightGray rounded-lg w-full pl-10 pr-3 py-2 placeholder:text-gray'} placeholder='Вулиця' type="text" value={schoolData.street} onChange={(e) => setSchoolData({ ...schoolData, street: e.target.value })} />
            </div><div className='relative mt-4'>
                <textarea required className={false ? 'border border-red rounded-lg w-full pl-3 pr-3 py-1 placeholder:text-gray resize-none' : 'border border-lightGray rounded-lg w-full pl-3 pr-3 py-2 placeholder:text-gray resize-none'} placeholder='Про нас' type="text" value={schoolData.description} onChange={(e) => setSchoolData({ ...schoolData, description: e.target.value })} />
            </div>
            <ButtonBase className='rounded-full mx-auto block mt-7'>
                <div className='bg-yellow text-lg rounded-full px-8 py-2 w-min cursor-pointer font-semibold transition hover:bg-hoverYellow'>Додати</div>
            </ButtonBase>
        </div>
    )
}

export default SchoolPopup