import React from 'react';
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import './menu.scss';
import FaceRoundedIcon from '@mui/icons-material/FaceRounded';
import { Logout } from '@mui/icons-material';
import { openPopup } from '../../actions/popup';
import { openNotification } from '../../actions/notification';

const mapDispatchToProps = dispatch => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  }
})


const Menu = (props) => {
  const {openPopup, openNotification} = props;

  const logOut = () => {
    localStorage.removeItem("accessToken");
    window.location.reload();
 }


  return (
    <div className='py-5'>
      <div className='bg-blue px-5 py-4 rounded-2xl flex items-center justify-between text-white'>
        <div className='w-32 leading-5 text-lg'>Розвиваємо освіту <span className='font-bold'>спільно</span></div>
        <div className='flex'>
          <NavLink
            to="/schools"
            className={({ isActive, isPending }) =>
              isPending ? "menuItem" : isActive ? "menuItem menuItem--active" : "menuItem"
            }
          >
            Школи
            <div className='menuItem__shadow'></div>
          </NavLink>
          <NavLink
            to="/teachers"
            className={({ isActive, isPending }) =>
              isPending ? "menuItem" : isActive ? "menuItem menuItem--active" : "menuItem"
            }
          >
            Вчителі
            <div className='menuItem__shadow'></div>
          </NavLink>
          <NavLink
            to="/classes"
            className={({ isActive, isPending }) =>
              isPending ? "menuItem" : isActive ? "menuItem menuItem--active" : "menuItem"
            }
          >
            Класи
            <div className='menuItem__shadow'></div>
          </NavLink>
          <NavLink
            to="/students"
            className={({ isActive, isPending }) =>
              isPending ? "menuItem" : isActive ? "menuItem menuItem--active" : "menuItem"
            }
          >
            Учні
            <div className='menuItem__shadow'></div>
          </NavLink>
        </div>
        <div className='flex items-center'>
          <img className='cursor-pointer box-content w-4 px-3' src='./images/ico/search.svg' alt="search" />
          {/* <img className='cursor-pointer box-content w-5 px-3' src='./images/ico/bell.svg' alt="notifications" /> */}
          {/* <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            placement="top"
            variant="outlined"
            arrow
            title={<div>
              <div className='text-xs font-semibold'>Are you sure you want to log out?</div>
              <div className='flex gap-5'>
                <Button>Logout</Button>
              </div>
            </div>}
          > */}

            <img onClick={() => openPopup({ 
                     currentPopup: 'Confirmation', 
                     info: {
                        text: 'logout', 
                        title: 'Точно хочете вийти?',
                        confirmationText: 'Ви впевнені, що хочете вийди?', 
                        confirmationBtnText: 'Вийти',
                        onConfirm: () => logOut()} 
                  })} className='cursor-pointer box-content w-5 px-3' src='./images/ico/logout.svg' alt="notifications" />

          {true ?
            <div className='cursor-pointer flex mx-3 items-center justify-center w-10 h-10 rounded-full overflow-hidden'>
              <img src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" className='object-cover' />
            </div>
            :
            <div className='cursor-pointer mx-3'>
              <FaceRoundedIcon />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default connect(null, mapDispatchToProps)(Menu);
