import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { connect } from "react-redux";
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import ButtonBase from '@mui/material/ButtonBase';
import EditDeleteButtons from '../../components/editDeleteButtons/editDeleteButtons';
import { NavLink } from "react-router-dom";
import { openPopup } from '../../actions/popup';
import { findSchools } from '../../service/requests';
import { setSchools } from "../../actions/schools";
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from 'lodash';
import {
  checkLastElem,
  setDataLoader,
  setPageName,
} from "../../actions/search";

const mapStateToProps = (state) => ({
  ...state.schools,
  ...state.search,
});

const mapDispatchToProps = (dispatch) => ({
  openPopup: (payload) => {
    return dispatch(openPopup(payload));
  },
  setSchools: (payload) => {
    return dispatch(setSchools(payload));
  },
  // clearRedux: () => {
  //   return dispatch({ type: "USER_LOGOUT" });
  // },
  checkLastElem: (payload) => {
    return dispatch(checkLastElem(payload));
  },
  setDataLoader: (payload) => {
    return dispatch(setDataLoader(payload));
  },
  setPageName: (payload) => {
    return dispatch(setPageName(payload));
  },
});

const Schools = (props) => {
  const { openPopup, schools, checkLastElem, setDataLoader, setPageName, isDataLoading } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const limit = 21;
  const [hasMore, setHasMore] = useState(false);
  const observer = useRef();
  const [searchWord, setSearchWord] = useState('');
  const [searchWordVal, setSearchWordVal] = useState('');

  useEffect(() => {
    if (isLoading)
      return;

    if (searchWord)
      setPageNumber(0);

    loadSchools();
  }, [searchWord, pageNumber]);

  const lastSchoolElementRef = useCallback(
    (node) => {
      if (isDataLoading || !node) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasMore]
  );

  const loadSchools = async () => {
    setIsLoading(true);
    try {
      const res = await findSchools(searchWord, pageNumber, limit).catch(
        (e) => console.log(e)
      );
      if (pageNumber == 0) {
        setSchools({ schools: res.data.schools });
      } else {
        const list = [...schools, ...res.data.schools];
        // remove duplicates
        const ids = list.map((o) => o._id);
        const filtered = list.filter(
          ({ _id }, index) => !ids.includes(_id, index + 1)
        );
        setSchools({ schools: filtered });
      }
      setHasMore(res.data.collections.length >= limit);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };


  const onSearchInputChange = (val) => {
    setSearchWord(val);
  };
  const debouncedEventHandler = useMemo(
    () => debounce(onSearchInputChange, 500)
    , []);

  return (
    <div className='content'>
      <section className='flex items-center justify-between'>
        <div className='flex items-center'>
          <div className='pageTitle'>Школи</div>
          <div className='pageSearchWrapper'>
            <img src="./images/ico/searchGray.svg" className='w-4' alt="search icon" />
            <input className='pageSearch' type="text" placeholder='Пошук ...' value={searchWordVal} onChange={(e) => { debouncedEventHandler(e.target.value); setSearchWordVal(e.target.value) }} />
          </div>
        </div>
        <ButtonBase className='rounded-full' onClick={() => openPopup({ currentPopup: "SchoolPopup", info: { isEditMode: false, title: 'Додати школу' } })}>
          <div className='bg-yellow rounded-full px-5 py-2 cursor-pointer font-semibold transition hover:bg-hoverYellow'>+ Додати</div>
        </ButtonBase>
      </section>

      <section className='flex flex-col mt-10'>
        {schools &&
          schools.length > 0 &&
          schools.map((school, i) => {
            return (
              <NavLink to={{
                pathname: `/school/${school._id}`,
                // state: {
                // collectionName: collection.name,
                // },
              }} ref={schools.length === i + 1 ? lastSchoolElementRef : null}>
                <div className='w-full border border-lightGray py-3 px-5 flex items-center rounded-3xl justify-between transition hover:border-lightPurple'>
                  <div className='flex items-center'>
                    <div className='rounded-full w-16 h-16 overflow-hidden'>
                      <img src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" className='object-cover' />
                    </div>
                    <div className='ml-5'>
                      <div className='text-lg font-semibold'>Відродження, приватна середня загальноосвітня школа</div>
                      <div className='flex items-center mt-1'>
                        <div className='flex items-center'>
                          <LocationOnRoundedIcon className='text-blue' />
                          <div className='ml-2'>Львів, вул. Липинського, 1</div>
                        </div>
                        <div className='flex items-center ml-5'>
                          <LocalPhoneRoundedIcon className='text-blue' />
                          <div className='ml-2'>252-03-01</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex gap-10 font-medium'>
                    <EditDeleteButtons />
                  </div>
                </div>
              </NavLink>
            );
          })
        }

        {isLoading && <div className='flex justify-center'><CircularProgress sx={{
          color: '#4B58FF'
        }} /></div>}

        {!isLoading && schools && schools.length == 0 && (
          <div className='flex flex-col items-center'>
            <img className='w-20' src="./images/ico/noData.svg" />
            <p className='mt-5 text-lg'>Наразі немає доданих шкіл</p>
          </div>
        )}

      </section>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Schools);