// import {Suspense, useEffect, useState} from "react";
// import {connect} from "react-redux";
// import {openNotification} from "./actions/notification";
// import { Routes, Route, useNavigate } from 'react-router-dom';
// import Schools from './pages/schools/schools';
// import School from './pages/school/school';

// const mapStateToProps = (state) => ({
//   ...state.pages,
// });

// const mapDispatchToProps = (dispatch) => ({
//   openNotification: (payload) => {
//     return dispatch(openNotification(payload));
//   },
// });

// const ProtectedRoutes = (props) => {

//   // const {openNotification, user, isNotFound} = props;
//   // const navigate = useNavigate();
//   // const [allRoutes, setAllRoutes] = useState(routes);

//   // useEffect(() => {
//   //   if (user.role === "teacher") {
//   //     const filteredRoutes = allRoutes.filter((x) => !x.onlyStudent);
//   //     setAllRoutes(filteredRoutes);
//   //   }
//   // }, []);

//   // useEffect(() => {
//   //   console.log('isNotFound', isNotFound);
//   //   if(isNotFound) {
//   //     navigate("/404");
//   //   }
//   // }, [isNotFound]);

//   return (
//     <Suspense
//       // fallback={
//       //   <div className="animatedLoaderLogoWrapper">
//       //     <img className="animatedLoaderLogo" src="/images/logo.svg" />
//       //   </div>
//       // }
//     >
//       <Route path="/" element={<Schools />} exact={true} />
//               <Route path="/schools" element={<Schools />} exact={true} />
//               <Route path="schools/:schoolId" element={<School />}exact={true} />
//     </Suspense>
//   );
// };

// export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoutes);

import {Route, Navigate} from 'react-router-dom';

const ProtectedRoute = ({user, children }) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};
export default ProtectedRoute
