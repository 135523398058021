import {
   SET_SCHOOLS,
   ADD_SCHOOL,
   UPDATE_SCHOOL,
   DELETE_SCHOOL,
} from './index';


export const setSchools = (payload) => {
   const { schools } = payload;
   return {
       type: SET_SCHOOLS,
       schools
   }
}

export const addSchools = (payload) => {
   const { school } = payload;
   return {
       type: ADD_SCHOOL,
       school
   }
}

export const updateSchool = (payload) => {
   const { school } = payload;
   return {
       type: UPDATE_SCHOOL,
       school
   }
}

export const deleteSchool = (payload) => {
   const { school } = payload;
   return {
       type: DELETE_SCHOOL,
       school
   }
}