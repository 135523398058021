import axios from 'axios';
// import store from '../store';
// import { setIsNotFound } from '../actions/pages';

axios.interceptors.request.use(config => {
    const token = localStorage.getItem('accessToken');

    config.headers = {
        'content-type': 'application/json',
        'x-accesstoken': (token) ? token : ''
    }

    return config;
});

axios.interceptors.response.use(
    response => response, err => {
    console.log(err, 'errrr');

        // if(err.response?.status === 401 && window.localStorage.getItem('token')) {
        //     window.localStorage.clear();
        //     window.location.reload();
        //     return err;
        // }
        
        // if(err.response.status === 404) {
        //     store.dispatch(setIsNotFound({isNotFound: true}));
        // }

    return Promise.reject(err.response);
});


export const baseURL = 'https://api.catch-up.one/';

const api = baseURL + 'api/v1/';
const usersAPI = api + 'users/';
const schoolsAPI = api + 'schools/'

// USERS API
export const loginUser = (payload) => axios.post(usersAPI + "login", payload);
// export const getUser = () => axios.get(usersAPI + "me");
// export const checkAuth = () => axios.get(usersAPI + "check");
// export const checkUserExist = (payload) => axios.post(usersAPI + "exist", payload);


// SCHOOLS API
export const findSchools = (name, page, limit) => {
    const params = new URLSearchParams({
        name,
        page,
        limit
    });

    removeNullsAndUndefined(params);
    return axios.get(schoolsAPI + 'search'+ '?' + params.toString());
}



export const removeNullsAndUndefined = (params) => {
    let keysForDel = [];
    params.forEach((value, key) => {
        if (value === "null" || value === "undefined" ) {
            keysForDel.push(key);
        }
    });

    keysForDel.forEach(key => {
        params.delete(key);
    });
}