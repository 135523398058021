import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ButtonBase from '@mui/material/ButtonBase';

import { closePopup } from "../../../actions/popup";
import { openNotification } from "../../../actions/notification";
// import trashIcon from "../../../img/ico/trashIcon.svg";

const mapDispatchToProps = (dispatch) => ({
  closePopup: () => {
    return dispatch(closePopup());
  },
  openNotification: (payload) => {
    return dispatch(openNotification(payload));
  },
});

const Confirmation = (props) => {
  const {
    closePopup,
    info,
    //   openNotification
  } = props;

  const [isLoading, setIsLoading] = useState(props.info.isLoading);

  const onConfirm = () => {
    setIsLoading(true);
    closePopup();
    info.onConfirm();
  };

  return (
    <section className="relative">
      {isLoading ? (
        <div className="">
          <div className='animatedLoaderLogoWrapper'><img className='animatedLoaderLogo' src="/images/logo.svg" /></div>
        </div>
      ) : (
        <>
          <div className="mt-5 text-lg text-left text-darkGray">{info.confirmationText}</div>
          <div className="mx-auto w-min mt-10 flex gap-5 mt-10">
            <ButtonBase className='rounded-full' onClick={() => closePopup()} >
              <div className='bg-lightPurple text-blue w-36 rounded-full p-3 cursor-pointer font-semibold transition hover:bg-lightPurple'>Скасувати</div>
            </ButtonBase>
            <ButtonBase className='rounded-full' onClick={() => onConfirm()}>
              <div className='bg-yellow rounded-full w-36 p-3 cursor-pointer font-semibold transition hover:bg-hoverYellow'>{info.confirmationBtnText}</div>
            </ButtonBase>
          </div>
        </>
      )}
    </section>
  );
};

export default connect(null, mapDispatchToProps)(Confirmation);
