import React, { useEffect, useState } from 'react';
import {connect} from "react-redux";
import { loginUser } from "../../service/requests";
import { useNavigate } from "react-router-dom";

import {currentUser} from "../../actions/currentUser";

const mapStateToProps = (state) => ({
    ...state.currentUser,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    currentUser: (payload) => {
      return dispatch(currentUser(payload));
    },
  });

const Login = (props) => {
    const {currentUser} = props;
    const navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState(null);
    const [loginError, setLoginError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState({
        login: 'admin',
        password: 'gSgwh31g3Hfsh3h'
    })

    useEffect(() => {
        if (userData.email != "") setLoginError(false);
        if (userData.password != "") setPasswordError(false);
    }, [userData]);


    // validation
    const validate = () => {
        let loginError = "";
        let passwordError = "";

        if (!userData.login) {
            loginError = "Email cannot be blank";
        }

        if (!userData.password) {
            passwordError = "Password cannot be blank";
        }

        if (loginError || passwordError) {
            setLoginError(loginError);
            setPasswordError(passwordError);
            return false;
        }
        return true;
    };



    const login = async () => {
        const isValid = validate();
        setErrorMessage("");

        if (isValid && !isLoading) {
            setIsLoading(true);
            console.log('userData', userData)
        }
        try {
            const res = await loginUser(userData);
            localStorage.setItem("accessToken", res.data.token);
            currentUser({user: true});
            // navigate('/');

        } catch (e) {
            console.log('e', e)
            setIsLoading(false);
        }

    }

    return (
        <div className='w-full h-screen flex items-center justify-center'>
            <div className='bg-white px-24 py-20 rounded-3xl w-max'>
                <div className='w-32 leading-5 text-lg text-blue text-center mx-auto'>Розвиваємо освіту <span className='font-bold underline'>спільно</span></div>
                <p className='font-bold text-2xl text-center mt-5'>Увійдіть в свій аккаунт</p>

                <form className='mt-7 w-80'>
                    <div className='relative'>
                        <img className='absolute top-2.5 left-3 w-4' src="./images/ico/user.svg" alt="login" />
                        <input autoFocus required className={loginError ? 'border border-red rounded-lg w-full pl-10 pr-3 py-1 placeholder:text-gray' : 'border border-lightGray rounded-lg w-full pl-10 pr-3 py-1 placeholder:text-gray'} placeholder='Логін' type="text" value={userData.login} onChange={(e) => setUserData({ ...userData, login: e.target.value })} />
                    </div>
                    <div className='relative mt-3'>
                        <img className='absolute top-2 left-3 w-4' src="./images/ico/password.svg" alt="password" />
                        <input required className={passwordError ? 'border border-red rounded-lg w-full pl-10 pr-3 py-1 placeholder:text-gray' : 'border border-lightGray rounded-lg w-full pl-10 pr-3 py-1 placeholder:text-gray'} placeholder='Пароль' type="password" value={userData.password} onChange={(e) => setUserData({ ...userData, password: e.target.value })} />
                    </div>
                    <div onClick={() => login()} className='bg-yellow rounded-full px-7 py-2 block mx-auto mt-7 w-min cursor-pointer font-semibold transition hover:bg-hoverYellow'>Увійти</div>
                </form>
            </div>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
